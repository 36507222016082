import {
  create as createClaimRequest,
  list as listClaimsRequest,
  cancel as cancelClaimRequest,
  courierPhone as getCourierPhoneRequest,
  confirmationCode as getConfirmationCodeRequest
} from '@/functions/axios/order/taxi_claim_requests'
import { i18n } from '@/i18n'

export default {
  namespaced: true,
  actions: {
    createClaimRequest,
    listClaimsRequest,
    cancelClaimRequest,
    getCourierPhoneRequest,
    getConfirmationCodeRequest,
    createClaim ({ dispatch, commit }, orderId) {
      commit('setLoading', { value: true }, { root: true })
      return dispatch('createClaimRequest', orderId).then(response => {
        dispatch('showSnackbar', i18n.t('claim.create'), { root: true })
      }).finally(() => {
        commit('setLoading', { value: false }, { root: true })
      })
    },
    getClaims ({ dispatch, commit }, orderId) {
      commit('setLoading', { value: true }, { root: true })
      return dispatch('listClaimsRequest', orderId).finally(() => {
        commit('setLoading', { value: false }, { root: true })
      })
    },
    cancelClaim ({ dispatch, commit }, claimId) {
      commit('setLoading', { value: true }, { root: true })
      return dispatch('cancelClaimRequest', claimId).then(response => {
        dispatch('showSnackbar', i18n.t('claim.canceled'), { root: true })
      }).finally(() => {
        commit('setLoading', { value: false }, { root: true })
      })
    },
    getCourierPhone ({ dispatch, commit }, claimId) {
      commit('setLoading', { value: true }, { root: true })
      return dispatch('getCourierPhoneRequest', claimId).finally(() => {
        commit('setLoading', { value: false }, { root: true })
      })
    },
    getConfirmationCode ({ dispatch, commit }, claimId) {
      commit('setLoading', { value: true }, { root: true })
      return dispatch('getConfirmationCodeRequest', claimId).finally(() => {
        commit('setLoading', { value: false }, { root: true })
      })
    }
  }
}
