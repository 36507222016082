<template>
  <div class="order-delivery-info">
    <v-card class="elevation-6">
      <v-card-text class="px-3">
        <h2 class="black--text">
          <v-icon class="px-1 black--text">mdi-car</v-icon>
          {{ $t('delivery') }}
        </h2>
        <div class="black--text py-1">
          <span class="grey--text"><v-icon>mdi-map-marker</v-icon></span>
          {{ order.street }}, {{ order.house }}
        </div>
        <div class="d-flex black--text py-1">
          <div class="d-flex align-center">
            <span class="d-flex grey--text mr-1">
              <span v-if="order.courier_service_id && order.courier.icon">
                <v-img class="mr-1" max-height="24" max-width="24" :src="order.courier.icon"></v-img>
              </span>
              <span v-else>
                <v-icon class="mr-1">mdi-face</v-icon>
              </span>
              {{ $t('courier') }}:
            </span>

            <template v-if="order.courierID !== 0 || order.courier_service_id">
              {{ order.courier.name }}
            </template>

            <template v-if="!order.completed && order.isSelectCourier">
              <div class="d-flex" style="gap: 10px" v-if="order.courierID === 0 && !order.courier_service_id">
                <v-btn v-if="!hasClaim" class="custom-btn" color="error" outlined @click="selectCourierList">
                  <v-icon class="px-1">mdi-gesture-tap</v-icon>
                  {{ $t('order_modal.btn.assign_courier') }}
                </v-btn>
                <v-btn
                  v-if="canCallTaxi && !hasClaim"
                  class="custom-btn" color="error" outlined @click="callTaxi">
                  <v-icon class="px-1">mdi-taxi</v-icon>
                  {{ $t('order_modal.btn.call_taxi') }}
                </v-btn>
              </div>

              <template v-else-if="!order.courier_service_id">
                <v-btn class="ma-1" outlined color="indigo" @click="selectCourierList">
                  <v-icon class="px-1" left>mdi-pencil</v-icon>
                  {{ $t('change') }}
                </v-btn>
              </template>

              <template v-if="canCallTaxi ?? hasClaim">
                <v-btn class="ma-1" outlined color="indigo" @click="showClaims">
                  <v-icon class="px-1" left>mdi-taxi</v-icon>
                  {{ $t('claim.many') }}
                </v-btn>
              </template>
            </template>
          </div>

          <div class="mt-1" v-if="!canCallTaxi && order.courier_service_id && !order.skip_confirmation">
            <v-btn color="amber lighten-2" class="mx-1" elevation="2" @click="openCodesModal()">
              {{ $t('confirmation_code') }}
            </v-btn>
          </div>
        </div>

        <div class="black--text py-1" v-if="order.onlinePayment.isActive === false">
          <span class="grey--text">
            <v-icon>mdi-cash-register</v-icon> {{ $t('order_modal.delivery.from_client') }}:
          </span>

          {{ getAmountFormat(cashByOrderPayments) }}
          <template v-if="cashByOrderPayments > cashAmount">
            <v-chip color="red" text-color="white" small>
              <v-icon>mdi-alert-circle-outline</v-icon> &nbsp;&nbsp;
              {{ $t('order_modal.delivery.msg.change', { change: getAmountFormat(cashByOrderPayments - cashAmount) }) }}
            </v-chip>
          </template>
        </div>
        <div class="black--text py-1" v-if="order.isSelectCourier">
          <span class="grey--text"><v-icon>mdi-message-reply-text</v-icon>
            {{ $t('order_modal.delivery.comment_for_courier') }}:
          </span>

          <template v-if="order.deliveryComment !== ''">
            <div class="px-2"> {{ order.deliveryComment }}</div>
          </template>
        </div>
      </v-card-text>
    </v-card>

    <couriers-modal
      :couriers="courierModal.list"
      :visible="courierModal.visible"
      :order="order"
      @hide="courierModal.visible = false"
    />

    <error-modal
      :visible="modalError.visible"
      :title="modalError.title"
      :message="modalError.message"
      @close="modalError.visible = false"
    />

    <taxi-claim-modal
      :visible="taxiModal.visible"
      :initial-claims="taxiModal.claims"
      @close="taxiModal.visible = false"
    />

    <confirm-order-modal
      :code="codesModal.code"
      :visible="codesModal.visible"
      :message="codesModal.message"
      @close="codesModal.visible = false"
    />
  </div>
</template>

<script>
import Order from '@/Models/Order'
import { getAmountFormat } from '@/helpers'
import CouriersModal from '@/components/Modals/CouriersModal.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import ErrorModal from '@/components/Modals/ErrorModal.vue'
import TaxiClaimModal from '@/components/Order/Modal/TaxiClaimModal.vue'
import ConfirmOrderModal from '@/components/Modals/ConfirmOrderModal.vue'

export default {
  name: 'OrderDeliveryInfo',
  components: {
    ConfirmOrderModal,
    TaxiClaimModal,
    ErrorModal,
    CouriersModal
  },
  props: {
    order: Order
  },
  data: () => ({
    courierModal: {
      visible: false,
      list: []
    },
    modalError: {
      visible: false,
      title: '',
      message: ''
    },
    taxiModal: {
      visible: false,
      claims: []
    },
    isCallTaxi: false,
    codesModal: {
      visible: false,
      message: {
        text: '',
        type: null
      },
      code: null
    }
  }),
  computed: {
    ...mapState(['user']),
    cashByOrderPayments () {
      return this.order.payment_types.reduce((out, item) => out + item.is_cash ? item.amount_change : 0, 0)
    },
    cashAmount () {
      return this.order.payment_types.reduce((out, item) => out + item.is_cash ? item.amount : 0, 0)
    },
    hasClaim () {
      return this.order.courier_service_id ?? this.isCallTaxi
    },
    canCallTaxi () {
      return this.user.cashier_access_taxi_claim && this.order.isOrderAccepted
    }
  },
  methods: {
    ...mapMutations([
      'setLoading'
    ]),
    ...mapActions([
      'getConfirmationCode',
      'showSnackbar'
    ]),
    ...mapActions('taxi_claims', [
      'createClaim',
      'getClaims'
    ]),
    getAmountFormat,
    selectCourierList () {
      this.setLoading({ value: true })
      this.order.courierList().then(response => {
        if (response.data.length === 0) {
          this.modalError.visible = true
          this.modalError.title = this.$t('order_modal.courier_modal.err.title')
          this.modalError.message = this.$t('order_modal.courier_modal.err.msg')
          return
        }

        response.data.push({ id: 0, name: this.$t('order_modal.courier_modal.without_courier') })
        this.courierModal.visible = true
        this.courierModal.list = response.data
      }).finally(() => {
        this.setLoading({ value: false })
      })
    },
    openCodesModal () {
      this.codesModal.message = { text: null, type: null }
      this.codesModal.code = null

      this.codesModal.loading = true
      this.getConfirmationCode({
        orderId: this.order.id
      }).then(response => {
        if (response.data) {
          this.codesModal.message = { text: '', type: null }
          this.codesModal.code = response.data.code
        } else {
          this.codesModal.message.text = this.$t('confirm_order.there_is_no_confirmation_code')
          this.codesModal.message.type = 'error'
        }
      }).catch(error => {
        this.codesModal.message.text = this.$t('error')
        this.codesModal.message.type = 'error'
        this.codesModal.length = 0

        if (error.response.data) {
          this.$store.dispatch('showSnackbar', error.response.data.message)
        }
      }).finally(() => {
        this.codesModal.loading = false
        this.codesModal.visible = true
      })
    },
    callTaxi () {
      this.createClaim(this.order.id).then(response => {
        this.isCallTaxi = true
      })
    },
    showClaims () {
      this.taxiModal.claims = []
      this.getClaims(this.order.id).then(response => {
        this.taxiModal.claims = response.data
        this.taxiModal.visible = true
      })
    }
  },
  mounted () {
    this.isCallTaxi = this.order.courier_service_id !== null
  },
  watch: {
    'order.courier_service_id' (val) {
      if (val !== null) {
        this.isCallTaxi = false
      }
    }
  }
}
</script>

<style >
.custom-btn {
  padding: 0 8px !important;
}
</style>
