const API_HOST = '/v2/order/'

export function list (cxt, params) {
  return this._vm.axios.get(API_HOST + 'list', { params })
}

export function filteredList (cxt, params) {
  return this._vm.axios.get(API_HOST + 'filtered-list', { params })
}

export function getOrder (cxt, order) {
  return this._vm.axios.get(API_HOST + order)
}
