const API_HOST = '/kassa/delivery-service/claim/'

export function create (context, orderId) {
  return this._vm.axios.post(API_HOST + `${orderId}`)
}

export function list (context, orderId) {
  return this._vm.axios.get(API_HOST + `list/${orderId}`)
}

export function courierPhone (context, claimId) {
  return this._vm.axios.get(API_HOST + `${claimId}/courier/phone`)
}

export function confirmationCode (context, claimId) {
  return this._vm.axios.get(API_HOST + `${claimId}/confirmation-code`)
}

export function cancel (context, claimId) {
  return this._vm.axios.patch(API_HOST + `${claimId}/cancel`)
}
