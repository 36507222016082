<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card class="mx-6 my-6 px-6 py-6 ">
          <v-form v-model="valid" ref="form" @keyup.enter.native="filterOrders()" lazy-validation>
            <v-row class="mx-auto">
              <v-col md="8">
                  <v-row v-if="!by_aggregator_order_id">
                    <v-col md="6">
                    <v-text-field
                        :label="$t('form.client_phone')"
                        :rules="rules.phone"
                        placeholder="7 xxx xxx xx xx"
                        v-model="search.phone"
                    ></v-text-field>
                    </v-col>
                      <v-col md="6">
                    <v-text-field
                        :label="$t('form.order_number')"
                        :rules="rules.order_id"
                        v-model="search.order_id"
                    ></v-text-field>
                        </v-col>
                  </v-row>
                  <v-row v-if="by_aggregator_order_id">
                    <v-col md="12">
                      <v-text-field
                          :label="$t('form.aggregator_order_number')"
                          :rules="rules.aggregator_order_id"
                          v-model="search.aggregator_order_id"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                <v-checkbox
                    :label="$t('form.by_aggregator_order_number')"
                    v-model="by_aggregator_order_id"
                    class="w-max-content"
                ></v-checkbox>
              </v-col>
              <v-col md="4">
                <v-btn block
                       style="height:100%;"
                       color="success"
                       :loading="loading"
                       :disabled="!valid || !hasFilledField"
                       @click="filterOrders()">
                  {{ $t('search') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <br>
          <v-alert
            border="top"
            colored-border
            type="info"
            :value="true"
            elevation="2"
          >
            {{ $t('search_page.desc') }}
          </v-alert>
        </v-card>

        <orders-table
          height="calc(100vh - 430px)"
          type="orders"
          :orders="orders"
          :loading="loading"
          :pagination="pagination"
          @changePage="filterOrders"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import OrdersTable from './OrdersTable'
import { mapActions } from 'vuex'

export default {
  components: {
    OrdersTable
  },
  data: () => ({
    loading: false,
    by_aggregator_order_id: false,
    valid: false,
    search: {
      phone: null,
      order_id: null,
      aggregator_order_id: null
    },
    orders: [],
    pagination: {
      page: 1,
      total: 0,
      per_page: 50
    }
  }),
  computed: {
    rules () {
      return {
        phone: [(val) => { return !val || (val.match(/^7\d{10}$/) != null) || this.$t('form.valid_msg.phone') }],
        order_id: [(val) => { return !val || (val.match(/^\d+$/) != null) || this.$t('form.valid_msg.order_number') }],
        aggregator_order_id: []
      }
    },
    hasFilledField () {
      let hasFilledField = false
      let i = 0
      for (i in this.search) {
        if (!this.search[i]) {
          continue
        }
        hasFilledField = true
        break
      }

      return hasFilledField
    }
  },
  methods: {
    ...mapActions([
      'loadFilteredOrders'
    ]),
    filterOrders (page = 1) {
      if (!this.$refs.form.validate() || !this.hasFilledField) {
        return
      }

      console.log(page)
      this.pagination.page = page
      this.loading = true

      this.loadFilteredOrders({
        filters: this.search
      }).then(response => {
        this.orders = response.orders
        this.pagination.total = response.pagination.total
        this.pagination.per_page = response.pagination.per_page
      }).finally(() => {
        this.loading = false
      })
    }
  },
  watch: {
    by_aggregator_order_id (n, o) {
      this.search = {
        phone: null,
        order_id: null,
        aggregator_order_id: null
      }
    }
  }
}
</script>
