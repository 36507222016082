
JSON.safeParse = function (json) {
  let parsed

  try {
    parsed = JSON.parse(json)
  } catch (e) {
    parsed = false
  }

  return parsed
}

export function logger (type = 'log', msg = '', stack = []) {
  console[type](msg, stack)
}

export function getAmountFormat (amount) {
  return Number(amount).toFixed(2).replace('.', ',')
}
