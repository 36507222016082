<template>
  <div>
    <v-dialog :value="visible" max-width="700px" @click:outside="close" persistent>
      <v-card class="elevation-6 pa-3 taxi-claim-dialog">
        <div class="d-flex justify-space-between pa-1">
          <div class="d-flex align-center">
            <v-icon>mdi-taxi</v-icon> {{ $t('claim.many') }}
          </div>
          <div>
            <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
          </div>
        </div>

        <v-expansion-panels>
          <v-expansion-panel
            v-for="claim in actualClaims"
            :key="`claim-${claim.primary_id}`"
          >
            <v-expansion-panel-header class="d-flex justify-space-between align-center">
              <div class="d-flex flex-column" style="gap: 5px">
                <div class="font-weight-bold">
                  ID: {{ claim.id ? claim.id : `${$t('claim.not_sent')} (${claim.primary_id})` }}
                </div>
                <div style="font-size: 14px;">{{ claim.detected_time }}</div>
              </div>
              <div class="text-end">
                {{ $t(claim.status_title) }}
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <claim
                :claim="claim"
                @show-code="openCodesModal"
                @show-phone="(phone) => openCourierModal({ phone })"
              />
            </v-expansion-panel-content>

          </v-expansion-panel>
        </v-expansion-panels>

        <div class="d-flex justify-end mt-3">
          <div>
            <v-btn @click="close">{{ $t('close') }}</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <confirm-order-modal
      :code="codesModal.code"
      :visible="codesModal.visible"
      :message="codesModal.message"
      @close="codesModal.visible = false"
    />
  </div>
</template>

<script>
import Claim from '@/components/Order/Claim.vue'
import ConfirmOrderModal from '@/components/Modals/ConfirmOrderModal.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TaxiClaimModal',
  components: { ConfirmOrderModal, Claim },
  props: {
    visible: Boolean,
    initialClaims: Array
  },
  data: () => ({
    codesModal: {
      visible: false,
      message: {
        text: '',
        type: null
      },
      code: null
    },
    interval: null,
    claims: []
  }),
  computed: {
    ...mapGetters([
      'order'
    ]),
    actualClaims () {
      let val = this.claims

      if (val.length < 1) {
        val = this.initialClaims
      }

      return val
    }
  },
  methods: {
    ...mapActions([
      'setLoading'
    ]),
    ...mapActions('taxi_claims', [
      'listClaimsRequest'
    ]),
    close () {
      clearInterval(this.interval)
      this.$emit('close')
    },
    updateClaims () {
      this.listClaimsRequest(this.order.id).then(response => {
        this.claims = response.data
      })
    },
    openCodesModal (code) {
      this.codesModal.message = { text: null, type: null }
      this.codesModal.code = null

      if (code) {
        this.codesModal.message = { text: '', type: null }
        this.codesModal.code = code
      } else {
        this.codesModal.message.text = this.$t('confirm_order.there_is_no_confirmation_code')
        this.codesModal.message.type = 'error'
      }

      this.codesModal.visible = true
    },
    openCourierModal (courier) {
      if (courier) {
        this.courierModal.courier = courier
      }

      this.courierModal.visible = true
    }
  },
  watch: {
    visible (val) {
      if (val === true && !this.interval) {
        this.interval = setInterval(this.updateClaims, 20000)
      }
    }
  }
}
</script>

<style scoped>

</style>
