<template>
  <div class="claim">
    <div class="claim-courier">
      <div class="claim-courier-info">
        <div>{{ claim.courier_name }}</div>
        <div>{{ claim.car_model }}</div>
        <div>{{ claim.car_number }}</div>
      </div>

      <div class="d-flex justify-space-between align-center">
        <div class="claim-courier-info-phone">
          <div v-if="courier.phone" class="d-flex align-center" style="gap: 10px">
            {{ $t('phone') }}: {{ courier.phone }}
            <v-btn color="info" small @click="getCourierPhone(claim.primary_id)">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </div>
          <v-btn v-else color="info" @click="getCourierPhone(claim.primary_id)">
            {{ $t('claim.courier_phone_number') }}
          </v-btn>
        </div>
        <div>
          <v-btn color="amber lighten-2" @click="getConfirmationCode(claim.primary_id)">
            {{ $t('claim.confirmation_code') }}
          </v-btn>
        </div>
      </div>
    </div>

    <div class="claim-actions d-flex justify-end" v-if="showCancelBtn">
      <v-btn color="error" @click="cancelClaim(claim.primary_id)">{{ $t('claim.cancel') }}</v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Claim',
  props: {
    claim: Object
  },
  data: () => ({
    confirmationCode: null,
    courier: {
      phone: null
    },
    showCancelBtn: false
  }),
  methods: {
    ...mapActions('taxi_claims', {
      cancelClaimRequest: 'cancelClaim',
      getCourierPhoneRequest: 'getCourierPhone',
      getConfirmationCodeRequest: 'getConfirmationCode'
    }),
    getConfirmationCode (id) {
      this.getConfirmationCodeRequest(id).then(response => {
        this.$emit('show-code', response.data.code)
      })
    },
    getCourierPhone (id) {
      this.getCourierPhoneRequest(id).then(response => {
        this.courier.phone = response.data.phone
      })
    },
    cancelClaim (id) {
      this.cancelClaimRequest(id).then(response => {
        this.showCancelBtn = false
      })
    }
  },
  mounted () {
    this.showCancelBtn = this.claim.cancel_button
  }
}
</script>

<style scoped>
.claim {
  font-size: 14px;
}

.claim-courier {
  margin-bottom: 1rem;
}
</style>
